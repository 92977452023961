'use strict';

import { get } from 'lodash';
import { EXTERNAL, INTERNAL } from '~/components/Nav/NavLink';
import { HOME_URI } from '~/constants/page-uri';
export default {
    methods: {
        mapType(type) {
            switch (type) {
            case 'linkSingle_internal_BlockType':
            case 'linkSingle_internalLink_BlockType':
            case 'links_internal_BlockType':
                return INTERNAL;
            case 'linkSingle_external_BlockType':
            case 'linkSingle_externalLink_BlockType':
            case 'links_external_BlockType':
                return EXTERNAL;
            }
        },

        mapURI(link) {
            const type = this.mapType(link.__typename);

            switch (type) {
            case INTERNAL:
                return `/${this.fixHomeUri(get(link, 'page[0].uri'))}`;
            case EXTERNAL:
                return get(link, 'linkUrl');
            }
        },

        mapSublinkURI(link) {
            switch (this.mapType(link.__typename)) {
            case INTERNAL:
            {
                const uri = get(link, 'internalLink[0].page[0].uri', ''),
                    url = this.fixHomeUri(uri);

                return this.localePath(`/${url}`);
            }
            case EXTERNAL:
                if (typeof link === 'string') {
                    if (link.externalLink[0] === '/') {
                        // If route parameter is a path, create route object with path.
                        return this.localePath(link);
                    }
                }
                return link.externalLink[0].linkUrl;
            }
        },

        getLink(entry) {
            const uri = get(entry, 'uri', ''),
                url = this.fixHomeUri(uri);

            return `/${url}`;
        },

        fixHomeUri(uri) {
            if (uri === HOME_URI) {
                return this.localePath('');
            }

            return uri;
        }
    }
};
