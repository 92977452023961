import { render, staticRenderFns } from "./PageError.vue?vue&type=template&id=6792c0f8"
import script from "./PageError.vue?vue&type=script&lang=js"
export * from "./PageError.vue?vue&type=script&lang=js"
import style0 from "./PageError.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FlexibleSectionTitle: require('/vercel/path0/components/FlexibleSection/FlexibleSectionTitle.vue').default,FlexibleSections: require('/vercel/path0/components/FlexibleSections/FlexibleSections.vue').default,TemplateDefault: require('/vercel/path0/components/TemplateDefault/TemplateDefault.vue').default,QueryError: require('/vercel/path0/components/Query/QueryError.vue').default})
