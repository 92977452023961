import { render, staticRenderFns } from "./default.vue?vue&type=template&id=66a62a18"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheEscapeHatch: require('/vercel/path0/components/TheEscapeHatch/TheEscapeHatch.vue').default,TheHamburgerMenu: require('/vercel/path0/components/TheHamburgerMenu/TheHamburgerMenu.vue').default,TheMenuOverlay: require('/vercel/path0/components/TheMenuOverlay/TheMenuOverlay.vue').default,CookieBar: require('/vercel/path0/components/CookieBar/CookieBar.vue').default,TheFooter: require('/vercel/path0/components/TheFooter/TheFooter.vue').default})
