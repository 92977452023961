// Fix for 100vh not correctly rendering on some mobile devices when browser chrome is visible.
// Taken from https://medium.com/quick-code/100vh-problem-with-ios-safari-92ab23c852a8

const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--appHeight', `${window.innerHeight}px`);
};

const scrolled = () => {
    if (window.scrollY > 0) {
        document.body.setAttribute('data-has-scrolled', true);
    } else {
        document.body.removeAttribute('data-has-scrolled');
    }
};

export default function() {
    window.addEventListener('resize', appHeight);
    window.addEventListener('scroll', scrolled);
    appHeight();
}
