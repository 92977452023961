
import getErrorQuery from '~/graphql/queries/Error.graphql';
import QueryMixin from '~/mixins/query.js';

export default {
    mixins: [
        QueryMixin
    ],

    fetchKey: 'query-error',

    async fetch() {
        const { data } = await this.$gql.executeQuery(getErrorQuery);

        await this.setEntryOrNotFound(data);
    },

    head() {
        return this.seo;
    }
};
