
import DnModal from '@digitalnatives/modal';
import getCraftUri from '~/util/urls';

export default {
    components: {
        DnModal
    },

    props: {
        mainNavigation: {
            type: Array,
            default: null
        },
    },

    data() {
        return {
            activeNavKey: null,
            visible: false,
        };
    },

    computed: {
        uri() {
            return getCraftUri(this, this.$route.path);
        }
    }
};
