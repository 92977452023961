import getDefaultSeoQuery from '~/graphql/queries/DefaultSeo.graphql';

class SEOService {
    constructor($i18n, $gql) {
        this.$i18n = $i18n;
        this.$gql = $gql;
    }

    async getDefault() {
        const { data } = await this.$gql.executeQuery(getDefaultSeoQuery);
        if (!data?.globalSet?.seo) {
            // eslint-disable-next-line no-console
            console.warn('No default global fallback for seo data');
            return {};
        }
        return data.globalSet.seo;
    }

    async getMetaData(seo = {}) {
        this.default = await this.getDefault();

        const title = seo.title || this.default.title || 'CER - Website';

        const metaData = {
            title,
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: seo.description || this.default.description || 'CER - Cultural Emergency Response'
                },
                ...this.getFbFields(seo),
                ...this.getTwFields(seo),
                this.getRobots(seo)
            ]
        };

        if (this.getCanonical(seo)) {
            metaData.link = [
                this.getCanonical(seo)
            ];
        }

        return metaData;
    }

    getFbFields(seo) {
        if (seo.social && seo.social.facebook) {
            return Object.keys(seo.social.facebook).map((key) => {
                if (key === 'image' && seo.social.facebook[key]) {
                    return {
                        hid: 'og:' + key,
                        property: 'og:' + key,
                        content: seo.social.facebook[key].url || this.default.social.facebook[key]?.url
                    };
                } else if (key === 'image' && seo.featuredimage) {
                    return {
                        hid: 'og:' + key,
                        property: 'og:' + key,
                        content: seo.featuredimage.urlLarge
                    };
                } else if (
                    key === 'image' &&
                    this.default?.social?.facebook?.image?.url
                ) {
                    return {
                        hid: 'og:' + key,
                        property: 'og:' + key,
                        content: this.default.social.facebook.image.url
                    };
                }

                return {
                    hid: 'og:' + key,
                    property: 'og:' + key,
                    content: seo.social.facebook[key] || this.default.social.facebook[key]
                };
            });
        }
        return [];
    }

    getTwFields(seo) {
        if (seo.social && seo.social.twitter) {
            return Object.keys(seo.social.twitter).map((key) => {
                if (key === 'image' && seo.social.twitter[key]) {
                    return {
                        hid: 'twitter:' + key,
                        name: 'twitter:' + key,
                        content: seo.social.twitter[key].url
                    };
                } else if (
                    key === 'image' &&
                    this.default?.social?.twitter?.image?.url
                ) {
                    return {
                        hid: 'twitter:' + key,
                        property: 'twitter:' + key,
                        content: this.default.social.twitter.image.url
                    };
                }
                return {
                    hid: 'twitter:' + key,
                    name: 'twitter:' + key,
                    content: seo.social.twitter[key] || this.default.social.twitter[key]
                };
            });
        }
        return [];
    }

    getRobots(seo) {
        let content = 'all';

        if (seo.advanced && seo.advanced.robots) {
            content = seo.advanced.robots.join(', ');
        }

        return {
            hid: 'robots',
            name: 'robots',
            content
        };
    }

    getCanonical(seo) {
        if (seo.advanced && seo.advanced.canonical) {
            return {
                rel: 'canonical',
                href: seo.advanced.canonical
            };
        }

        return false;
    }
}

export default {
    install(VueInstance, VueI18n, VueGql) {
        VueInstance.prototype.$seo = VueInstance.$seo = new SEOService(VueI18n, VueGql);
    }
};
