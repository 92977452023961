
import getMainNavigationQuery from '~/graphql/queries/MainNavigation.graphql';

export default {
    data() {
        return {
            menuVisible: false,
            globalSet: null
        };
    },

    async fetch() {
        const { data } = await this.$gql.executeQuery(
            getMainNavigationQuery
        );

        if (data && data.globalSet) {
            this.globalSet = data.globalSet;
        }
    }
};
