
export default {
    computed: {
        logoType() {
            if (
                this.$route.path === '/' ||
                (
                    !process.server &&
                    this.$menu.isOpen &&
                    window.matchMedia('(max-width: 576px)').matches
                )
            ) {
                return 'light';
            }
            return 'dark';
        }
    },
};
