
export default {
    props: {
        width: {
            type: String,
            default: 'page',
            validator(value) {
                return ['page', 'content', 'content-center', 'bleed'].includes(value);
            }
        },
        background: {
            type: String,
            default: 'default',
            validator(value) {
                return ['default', 'none', 'lightest'].includes(value);
            }
        }
    }
};
