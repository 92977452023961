import { render, staticRenderFns } from "./TheFooter.vue?vue&type=template&id=ffb8fe20"
import script from "./TheFooter.vue?vue&type=script&lang=js"
export * from "./TheFooter.vue?vue&type=script&lang=js"
import style0 from "./TheFooter.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImage: require('/vercel/path0/components/Base/Image/BaseImage.vue').default,NewsletterSignup: require('/vercel/path0/components/NewsletterSignup/NewsletterSignup.vue').default,SocialButtons: require('/vercel/path0/components/SocialButtons/SocialButtons.vue').default,QualityLogos: require('/vercel/path0/components/QualityLogos/QualityLogos.vue').default})
