
export default {
    props: {
        sections: {
            type: Array,
            required: true,
            default: () => { return []; }
        }
    },

    data() {
        return {
            map: {
                'flexibleSections_accordion_BlockType': 'FlexibleSectionAccordion',
                'flexibleSections_richText_BlockType': 'FlexibleSectionRichText',
                'flexibleSections_image_BlockType': 'FlexibleSectionImage',
                'flexibleSections_images_BlockType': 'FlexibleSectionImages',
                'flexibleSections_video_BlockType': 'FlexibleSectionVideo',
                'flexibleSections_quote_BlockType': 'FlexibleSectionQuote',
                'flexibleSections_callToAction_BlockType': 'FlexibleSectionCallToAction',
                'flexibleSections_statistics_BlockType': 'FlexibleSectionStatistics',
                'flexibleSections_quickLinks_BlockType': 'FlexibleSectionQuickLinks',
                'flexibleSections_services_BlockType': 'FlexibleSectionServices',
                'flexibleSections_textImage_BlockType': 'FlexibleSectionTextImage',
                'flexibleSections_textText_BlockType': 'FlexibleSectionTextText',
                'flexibleSections_textVideo_BlockType': 'FlexibleSectionTextVideo',
                'flexibleSections_stories_BlockType': 'FlexibleSectionStories',
                'flexibleSections_emergencySupportForm_BlockType': 'FlexibleSectionEmergencySupportForm',
                'flexibleSections_pdfEmbed_BlockType': 'FlexibleSectionPdfEmbed'
            }
        };
    }
};
