
export const INTERNAL = 'internal';
export const EXTERNAL = 'external';

export default {
    props: {
        type: {
            type: String,
            validator: value => [INTERNAL, EXTERNAL].includes(value),
            default: INTERNAL
        },
        url: {
            type: [String, Object],
            required: true
        },
        blank: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            INTERNAL,
            EXTERNAL
        };
    }
};
