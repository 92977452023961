import { extend, setInteractionMode } from 'vee-validate';
// eslint-disable-next-line camelcase
import { required, email, regex } from 'vee-validate/dist/rules.umd.js';

// Set interaction mode
setInteractionMode('eager');

extend('regex', {
    ...regex,
    message: 'The field value is not correct'
});

extend('email', {
    ...email,
    message: 'Please enter your e-mail address'
});

extend('required', {
    ...required,
    message: 'This field is required'
});
