
import { get } from 'lodash';

import Navigation from '~/mixins/Navigation';

export default {
    mixins: [
        Navigation
    ],

    props: {
        link: {
            type: Object,
            required: true,
            default: () => { return {}; }
        }
    },

    computed: {
        linkSingle() {
            return get(this, 'link.linkSingle.0', []);
        },
        header() {
            return get(this, 'link.header', []);
        },
        children() {
            return get(this, 'link.children[0].links', []);
        }
    }
};
