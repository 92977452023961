
import DnLazyImg from '@digitalnatives/lazy-img';

export default {
    components: {
        DnLazyImg
    },

    extends: DnLazyImg,

    props: {
        credits: {
            type: String,
            default: null
        },
        aspectRatio: {
            type: String,
            default: 'original',
            validator(value) {
                return ['original', '1:1'].includes(value);
            }
        }
    }
};
