
export default {
    name: 'NewsletterSignup',
    data() {
        return {
            email: '',
            loading: false,
            error: false,
            success: false
        };
    },
    methods: {
        subscribeToEmail() {
            this.loading = true;
            this.error = false;
            this.success = false;

            // POST /subscribe-newsletter
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: this.email
                })
            };

            fetch('/api/subscribe-newsletter', options)
                .then(response => response.json())
                .then((data) => {
                    if (data.error) {
                        this.error = true;
                    } else {
                        this.email = '';
                        this.success = true;
                    }
                })
                .catch(() => {
                    this.error = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
