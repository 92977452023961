
import footerQuery from '~/graphql/queries/Footer.graphql';

export default {
    data() {
        return {
            footerData: null,
            showQualityLogos: true
        };
    },

    async fetch() {
        const { data } = await this.$gql.executeQuery(
            footerQuery
        );

        if (data && data.globalSet) {
            this.footerData = data.globalSet;
        }
    },

    computed: {
        mainNavigation() {
            if (!this.footerData || !this.footerData.links) {
                return [];
            }

            return this.footerData.links.map((craftLink) => {
                const key = Object.keys(craftLink)[0];
                const link = craftLink[key][0];

                if (key === 'externalLink') {
                    return {
                        type: 'externalLink',
                        label: link.label,
                        url: link.externalUrl,
                        openInNewTab: link.openInNewTab
                    };
                }

                if (key === 'internalLink') {
                    return {
                        type: 'internalLink',
                        label: link.label,
                        entry: link.entry[0],
                    };
                }

                return false;
            });
        },

        privacyNavigation() {
            if (!this.footerData || !this.footerData.extraLinks) {
                return [];
            }

            return this.footerData.extraLinks;
        },

        image() {
            if (!this.footerData || !this.footerData.image) {
                return;
            }

            return this.footerData.image[0];
        }
    }
};
