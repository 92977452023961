
'use strict';

export default {
    props: {
        iconOpen: {
            type: String,
            default: 'plus'
        },
        iconClose: {
            type: String,
            default: 'minus'
        },
        listenOutside: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            expanded: false
        };
    },

    computed: {
        icon() {
            if (this.iconOpen === this.iconClose) {
                return this.iconOpen;
            }
            return this.expanded ? this.iconClose : this.iconOpen;
        }
    },

    mounted() {
        if (this.listenOutside) {
            document.body.addEventListener('click', this.checkEvent);
        }
    },

    methods: {
        toggleContent() {
            this.expanded = !this.expanded;
        },

        checkEvent(event) {
            if (event.target.getAttribute('data-accordion-toggle') !== null) {
                return;
            }

            if (!event.target.closest('[data-accordion]') && this.expanded) {
                // eslint-disable-next-line no-console
                this.toggleContent();
            }
        }
    }
};
