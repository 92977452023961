export default {
    data() {
        return {
            entry: null,
            seo: null,
            notFound: false
        };
    },

    methods: {
        async setEntryOrNotFound(data) {
            if (data && data.entry) {
                this.entry = data.entry;
                this.seo = await this.$seo.getMetaData(data?.entry?.seo);
            } else {
                this.notFound = true;

                if (process.server) {
                    this.$nuxt.context.res.statusCode = 404;
                }
            }
        }
    },

    computed: {
        loadingState() {
            if (this.$fetchState.error || this.notFound) {
                return 'error';
            }

            if (!this.$fetchState.pending || this.entry) {
                return 'ready';
            }

            return 'loading';
        }
    }
};
