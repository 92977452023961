
export default {
    head() {
        return {
            bodyAttrs: {
                'data-background-color': 'lightest'
            }
        };
    }
};
